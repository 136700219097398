<template>
	<div class="card mb-3" v-if="isDemo">
		<div class="card-body p-0 ">
			<button
				class="btn w-100 h-100 text-primary fs-12 py-5"
				@click="switchBack"
			>
				<div
					class="d-flex align-items-center justify-content-center text-success"
				>
					<i class="icon-arrow-right-circle mr-1"></i>
					Switch to my workspace
				</div>
			</button>
			<!-- <button
        v-else
        class="btn w-100 h-100 text-primary fs-12 py-5"
        @click="switchToDemp"
      >
        <div class="d-flex align-items-center justify-content-center">
          <i class="icon-arrow-right-circle mr-1"></i>
          View Demo Workspace <spinner :busy="switching" v-if="switching" />
        </div>
      </button> -->
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import accessMixin from "@/mixins/accessMixin";
import moment from "moment";
export default {
	name: "demoProject",
	mixins: [accessMixin],
	data() {
		return {
			switching: false,
		};
	},

	methods: {
		async switchToDemp() {
			if (this.switching) return;
			this.switching = true;
			try {
				await this.$store.dispatch("SWITCH_TO_DEMO");
				this.$router.push("/projects");
			} catch (error) {
				console.log("error", error);
			} finally {
				this.switching = false;
			}
		},
		switchBack() {
			this.$router.push("/switchClient");
		},
	},
	computed: {
		...mapGetters(["isDemo", "client", "projects"]),
		show() {
			return (
				moment().diff(moment(this.client.dateCreated), "days") < 15 ||
				!this.client.hasProject
			);
		},
	},
};
</script>

<template>
	<card-layout
		:title="isnew ? 'New Payable' : 'Edit Payable'"
		:state="state"
		class="mb-l"
	>
		<div slot="header">
			<save-cancel @save="save" @cancel="cancel" />
		</div>
		<div class="card-body" slot="data">
			<div class="row">
				<div class="col-lg-6">
					<div class="bg-1 p-3 form-group rounded-5-top">
						<section class="form-group">
							<b-checkbox v-model="payable.sharedCustomer" switch size="lg"
								>Share with Client</b-checkbox
							>
						</section>
						<section class>
							<div class="row">
								<div class="col form-group">
									<label>Expense Type</label>
									<type-select
										:types="types"
										v-model="payable.type"
										@update="focusNext"
									/>
								</div>
								<div class="col form-group">
									<div class="float-right">
										<label>Expense Date</label>
										<date-input
											ref="datePicker"
											@change="dateChanged"
											v-model="payable.date"
										/>
									</div>
								</div>
							</div>
						</section>

						<section class="form-group" v-if="!project_id">
							<label>Project</label>
							<project-view
								:project="payable.project"
								class="form-control value"
								v-if="invoiced"
							/>
							<project-selector
								v-else
								name="project"
								:show-general="true"
								ref="projectView"
								:project="payable.project"
								placeholder="Select project"
								@change="changeProject"
								:class="{
									'is-invalid': validated && $v.payable.project.$invalid,
								}"
								class="form-control"
							/>
							<div
								class="invalid-feedback"
								v-if="validated && $v.payable.project.$invalid"
							>
								Please select a project
							</div>
						</section>
						<section class="form-group">
							<label>Work Item</label>
							<item-selector
								name="title"
								ref="itemView"
								:item="payable.item"
								placeholder="Select item"
								:class="{ 'is-invalid': validated && $v.payable.item.$invalid }"
								@change="itemChanged"
								:projectId="payable.project ? payable.project._id : ''"
								class="form-control"
							/>
							<div
								class="invalid-feedback"
								v-if="validated && $v.payable.item.$invalid"
							>
								Please select an item
							</div>
						</section>

						<section class="form-group">
							<label>Payee</label>
							<contact-selector
								name="payee"
								ref="contactSelector"
								:contact="payable.payee"
								placeholder="Select vendor / subcontractor"
								@change="changeContact"
								:class="{
									'is-invalid': validated && $v.payable.payee.$invalid,
								}"
								class="form-control"
							/>
							<div
								class="invalid-feedback"
								v-if="validated && $v.payable.payee.$invalid"
							>
								Please select a payee
							</div>
						</section>

						<section class>
							<label>Invoice Number</label>
							<input
								ref="refNo"
								type="text"
								id="refNo"
								v-model="payable.refNo"
								@change="checkRefNo"
								placeholder="e.g. cheque #, invoice #, PO#"
								class="form-control"
							/>
						</section>
						<div class="alert alert-danger mt-1" v-if="refNoExist">
							Same Invoice/RefNo Number has already been entered for this payee.
						</div>
					</div>
					<div v-if="payable.project && payable.item">
						<BudgetItemLine
							:itemId="payable.item._id"
							:projectId="payable.project._id"
						/>
					</div>

					<div class="bg-1 p-3">
						<div class="row">
							<div class="col-lg-8"></div>
							<div class="col-lg-8">
								<PayableAmount :payable="payable" v-if="invoiced" />
								<div v-else>
									<div class="row mb-1">
										<div class="col-sm-4">
											<label>Amount:</label>
										</div>
										<div class="col-sm-8">
											<amount-input
												ref="amountInput"
												@change="amountChanged"
												v-model="payable.amount"
												placeholder="Before tax "
												:class="{
													'is-invalid': validated && $v.payable.amount.$invalid,
												}"
											/>
											<div
												class="invalid-feedback"
												v-if="validated && $v.payable.amount.$invalid"
											>
												Please enter the amount
											</div>
										</div>
									</div>
									<tax-input
										class="mb-1"
										:tax="payable.tax1"
										:amount="payable.amount"
										@change="taxChange"
										v-if="payable.tax1"
									/>
									<tax-input
										class="mb-1"
										:tax="payable.tax2"
										:amount="payable.amount"
										@change="taxChange"
										v-if="payable.tax2"
									/>
									<div class="row">
										<div class="col-lg-4">
											<label>Total:</label>
										</div>
										<div class="col-lg-8">
											<amount-input
												ref="amountInput"
												@change="totalChanged"
												v-model="total"
												placeholder="Total Amount"
												class="font-weight-md"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="bg-1 mt-1 px-3 py-2 form-group" v-if="isnew">
						<inline-paymet
							:payable="payable"
							:payment="payment"
							ref="inlinePayment"
						/>
					</div>
					<div class="alert alert-info" v-if="invoiced">
						This expense has been invoiced as Management Fee and can not be
						modified.
						<br />To update the amount you can try removing it from the
						<router-link
							class="px-1 fw-600"
							v-if="payable.invoiceId"
							:to="
								`/project/${payable.project._id}/invoice/${payable.invoiceId}`
							"
							>invoice</router-link
						>
					</div>

					<div class="bg-1 p-3 mt-3 form-group">
						<section class="form-group">
							<label>Notes</label>
							<div>
								<textarea
									class="form-control"
									v-model="payable.note"
									rows="3"
									placeholder="Enter notes for this payable"
								></textarea>
							</div>
						</section>
					</div>

					<section class="form-group">
						<b-checkbox v-if="!invoiced" v-model="payable.noMngFee" switch
							>Do not include this expense in management fee</b-checkbox
						>
					</section>

					<payments :payable="payable" @done="paymentUpdated" v-if="!isnew" />
				</div>
				<div class="col-lg-6">
					<entity-attachments :entityName="'payable'" :entity="payable" />
				</div>
			</div>
		</div>
		<div slot="footer">
			<save-cancel @save="save" @cancel="cancel" />
		</div>
	</card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import newPayment from "./data/newPayment.js";
import InlinePaymet from "./components/InlinePaymet";
import TaxInput from "@/views/settings/tax/TaxInput";
import Payments from "./Payments";
import calc from "./data/calc.js";
import taxMixin from "@/views/settings/data/taxMixin";
import PayableAmount from "./PayableAmount";
import BudgetItemLine from "@/views/budgeting/components/BudgetItemLine";

import {
	required,
	numeric,
	minValue,
	maxValue,
	decimal,
} from "vuelidate/lib/validators";

import { validationMixin } from "vuelidate";
const entityName = "payable";
export default {
	name: "payableEdit",
	mixins: [dataMixin, baseMixin, routeMixin, taxMixin, validationMixin],
	components: {
		InlinePaymet,
		TaxInput,
		Payments,
		PayableAmount,
		BudgetItemLine,
	},
	data() {
		return {
			payable: {},
			total: "",
			payment: {},
			types: [],
			refNoExist: false,
		};
	},

	mounted() {
		this.types = this.expenseTypes;
		this.load();
	},

	methods: {
		async load() {
			this.loading();
			if (this.isnew) {
				this.payable = this.newPayable();
				this.payment = newPayment();
				this.loaded();
				this.focusDate();
			} else {
				await this.loadById(entityName, this.payable_id);
				this.init();
				this.loaded();
			}
		},

		newPayable() {
			let key = this.$route.query.type || 1;

			return {
				date: new Date(),
				type: { key: key, name: this.expenseTypes[key] },
				project: {
					_id: this.project_id,
					name: this.project ? this.project.name : "",
				},
				payee: { _id: "", name: "" },
				item: { _id: "", name: "" },
				title: "",
				note: "",
				amount: 0,
				refNo: "",
				tax1: this.newTax(1),
				tax2: this.newTax(2),
				exchange: this.getExchange(),
				attachments: [],
				payments: [],
				contractId: "",
				sharedCustomer: true,
			};
		},
		async checkRefNo() {
			if (!this.payable.payee || !this.payable.payee._id || !this.payable.refNo)
				return;

			let { exist } = await this.loadByAction(
				"payable",
				`sameRefNoExist?_id=${this.payable._id || ""}&refNo=${
					this.payable.refNo
				}&contactId=${this.payable.payee._id}`
			);
			this.refNoExist = exist.length > 0;
		},

		focusDate() {
			this.$nextTick(() => {
				setTimeout(() => {
					this.$refs.datePicker.focus();
				}, 300);
			});
		},

		async init() {
			let payable = this.payableById(this.payable_id);
			this.payable = JSON.parse(JSON.stringify(payable));
			this.updatTotal();
		},

		taxChange() {
			this.updatTotal();
		},

		amountChanged() {
			calc.updateTaxes(this.payable);
			this.updatTotal();
		},

		paymentUpdated() {
			this.init();
		},

		totalChanged(total) {
			calc.calcFromTotal(this.payable, total);
		},

		updatTotal() {
			this.total = calc.calcTotal(this.payable);
		},

		dateChanged(val) {
			this.focusNext();
		},

		changeProject(project) {
			this.focusNext();
		},

		changeContact(company) {
			this.focusNext();
		},

		itemChanged(item) {
			this.payable.title = item.name;
			this.focusNext();
		},

		focusNext() {
			setTimeout(() => {
				if (!this.payable.project._id) {
					this.$refs.projectView.show();
					return;
				}
				if (!this.payable.item._id) {
					this.$refs.itemView.show();
					return;
				}
				if (!this.payable.payee._id) {
					this.$refs.contactSelector.show();
					return;
				}

				if (this.payable.amount == 0) {
					this.$refs.refNo.focus();
					return;
				}
			}, 200);
		},

		async save() {
			this.validated = true;

			if (this.$v.$invalid) return;
			let payable = { ...this.payable };

			this.setPaymentIfNew(payable);
			try {
				payable = this.isnew
					? await this.add(entityName, payable)
					: await this.update(entityName, payable);
				this.toView(payable._id);
			} catch (error) {}
		},

		setPaymentIfNew(payable) {
			if (!this.isnew) return;

			//if unpaid
			if (!this.payment.amount || this.payment == "n") return;

			//if fulley paid
			if (this.payment == "f") {
				this.payment.amount = this.total;
			}
			payable.payments = [this.payment];
		},

		cancel() {
			this.$router.go(-1);
		},

		toView(id) {
			this.$router.go(-1);
		},
	},
	validations: {
		payable: {
			project: {
				_id: { required },
			},
			payee: {
				_id: { required },
			},
			item: {
				_id: { required },
			},
			type: {
				name: { required },
			},
			date: { required },
			amount: { required, decimal, minValue: minValue(0) },
		},
	},
	computed: {
		isnew() {
			return this.payable_id == "new";
		},
		project() {
			return this.projectById(this.project_id);
		},
		invoiced() {
			return !!this.payable.invoiceId;
		},
		isCostPlus() {
			return this.project &&
				this.project.contract &&
				this.project.contract.contractType
				? this.project.contract.contractType == "c"
				: false;
		},

		...mapGetters([
			"clientSetting",
			"payableById",
			"projectById",
			"expenseTypes",
		]),
	},
};
</script>

export default (list, keyword) => {
	if (list && keyword) {
		keyword = keyword.toLowerCase();
		return list.filter(
			(o) =>
				(o.name && o.name.toLowerCase().includes(keyword)) ||
				(o.code && o.code.toLowerCase().startsWith(keyword)) ||
				(o.description && o.description.toLowerCase().includes(keyword))
		);
	}
	return list;
};

export const sortbyCode = (a, b) => {
	// Convert codes to uppercase for case-insensitive comparison
	const codeA = a.code ? a.code.toUpperCase() : "";
	const codeB = b.code ? b.code.toUpperCase() : "";

	// Check if both codes are letters
	const isLetterA = /^[A-Z]$/.test(codeA);
	const isLetterB = /^[A-Z]$/.test(codeB);

	// If both are letters or both are not letters, compare directly
	if (isLetterA === isLetterB) {
		if (isLetterA) {
			// Both are letters, compare alphabetically
			return codeA.localeCompare(codeB);
		} else {
			// Both are numbers (or other), compare numerically
			return parseInt(codeA) - parseInt(codeB);
		}
	}

	// If one is a letter and the other is not, put the letter first
	return isLetterA ? -1 : 1;
};

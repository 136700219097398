<template>
	<div class="px-3">
		<h5 class="font-weight-5 bg-title p-2 m-0">Categories</h5>
		<ul class="list-group list-category">
			<li
				class="list-group-item list-group-item-action d-flex justify-content-between"
				:class="{ active: selected == 0 }"
				@click="select({ _id: 0 })"
			>
				All
				<small class="text-muted">({{ allSelectedCount }})</small>
			</li>
			<li
				@click="select(c)"
				class="list-group-item text-truncate c-pointer list-group-item-action d-flex justify-content-between align-items-center"
				v-for="c in list"
				v-bind:key="c._id"
				:class="{ active: selected == c._id }"
			>
				<div>
					<span class="d-inline-block text-truncate "> {{ c.code }}</span>
					<span class="d-inline-block text-truncate ml-3"> {{ c.name }}</span>
				</div>

				<span
					class="badge badge-success badge-pill px-2 mt-1"
					v-if="selectedCounts(c._id) > 0"
					>{{ selectedCounts(c._id) }}</span
				>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import pages from "@/pages";
export default {
	name: "itemCategorySelect",
	mixins: [dataMixin],
	props: {
		//items or budgetItem or tasks
		selectedItems: Array,
		showAll: { type: Boolean, default: true },
	},
	data() {
		return {
			selected: {},
		};
	},
	methods: {
		select(c) {
			this.selected = c._id;
			this.$emit("select", c);
		},
		clearSelected() {
			this.selected = "";
		},
	},
	computed: {
		list() {
			return this.showAll
				? this.categories
				: this.categories.filter(
						(c) =>
							this.selectedItems.map((i) => i.categoryId).indexOf(c._id) >= 0
				  );
		},
		selectedCounts() {
			return (catId) =>
				this.selectedItems
					? this.selectedItems.filter((o) => o.categoryId == catId).length
					: "";
		},
		allSelectedCount() {
			return this.selectedItems ? this.selectedItems.length : "";
		},

		...mapGetters(["categories", "items"]),
	},
};
</script>
<style lang="scss" scoped>
ul.list-category li {
	padding: 0.4rem;
	border-right-width: 0;
	border-left-width: 0;
}
.list-category li:first-child {
	border-bottom: 1px solid lightgray;
	margin-bottom: 1px;
}
</style>

<template>
	<div class="shadow-sm">
		<div class="px-3 py-2 bg-trans rounded-5-top fs-11">By Type</div>
		<div class="bg-white">
			<div class="row">
				<div class="col-lg-6">
					<table class="table mx-3">
						<thead>
							<tr>
								<th>Expense Type</th>
								<th class="text-right">Amount</th>
							</tr>
						</thead>
						<tr v-for="p in groupedList" :key="p.id">
							<td>{{ p.name }}</td>
							<td class="text-right">{{ p.amount | currency }}</td>
						</tr>
					</table>
				</div>
				<div class="col-lg-6">
					<highcharts :options="chartOptions" ref="hchart" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Chart } from "highcharts-vue";
import baseMixin from "@/mixins/baseMixin";
import { subTotal } from "../data/calc";
import _ from "lodash";
const getName = (list) => {
	let first = list.find((o) => o.type.name);
	return first ? first.type.name : "Other";
};
export default {
	name: "payableTypeChart",
	components: { highcharts: Chart },
	mixins: [baseMixin],
	data() {
		return {
			groupedList: [],
		};
	},
	props: {
		list: Array,
	},
	data() {
		return {
			chartOptions: {
				chart: {
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: "pie",
					height: 220,
				},

				title: {
					text: "",
				},
				tooltip: {
					pointFormat: "{series.name}: <b>{point.y:.1f}</b>",
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: "pointer",
						dataLabels: {
							enabled: true,
							format: "<b>{point.name}</b>: {point.percentage:.1f} %",
						},
					},
				},
				series: [
					{
						name: "Expense Type",
						colorByPoint: true,
						data: [],
					},
				],
			},
		};
	},
	watch: {
		total: {
			immediate: true,
			handler(value) {
				this.updateChart();
			},
		},
	},
	methods: {
		updateChart() {
			let data = _(this.list)
				.groupBy((p) => (p.type ? p.type.key : ""))
				.map((value, key) => ({
					key,
					value,
					name: getName(value),
					amount: value.map(subTotal).reduce((a, b) => a + b, 0),
				}))
				.orderBy((o) => o.amount, "desc")
				.value()
				.slice(0, 10);
			this.groupedList = data;

			this.chartOptions.series[0].data = data.map((o) => {
				return { name: o.name, y: o.amount };
			});
		},
	},
	computed: {
		total() {
			return this.list.map(subTotal).reduce((a, b) => a + b, 0);
		},
	},
};
</script>

<style lang="scss" scoped></style>

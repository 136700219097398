<template>
	<div class="px-3">
		<section class="form-group">
			<label for="itemCategoryName">Category Name</label>

			<input
				ref="nameInput"
				id="itemCategoryName"
				:class="{ 'is-invalid': validated && !category.name }"
				class="form-control mb-3"
				v-model="category.name"
				placeholder="Item Title"
			/>

			<div class="invalid-feedback" v-if="validated && !category.name">
				Please eneter the title
			</div>
			<div class="w-50">
				<label for="itemCategoryCode">Category Code</label>
				<input
					id="itemCategoryCode"
					:class="{ 'is-invalid': validated && !category.code }"
					class="form-control"
					v-model="category.code"
					placeholder="Code"
				/>
			</div>
		</section>
		<div class="py-3">
			<delete-confirm
				v-if="!isnew"
				class="d-inline-block position-absolute"
				:class="{ confirm: deleteMode }"
				entityName="itemMaster"
				@confirm="del"
				:entity="category"
				confirmtext="Are you sure you want to delete this item"
			/>
			<save-cancel @save="done" @cancel="$emit('cancel')" class="float-right" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin.js";
const entityName = "itemMaster";
export default {
	name: "itemCategoryEdit",
	mixins: [dataMixin],
	props: {
		category: Object,
	},
	data() {
		return {
			validated: false,
			deleteMode: false,
		};
	},
	mounted() {
		this.focus();
	},
	watch: {
		category() {
			this.focus();
		},
	},
	methods: {
		focus() {
			setTimeout(() => {
				this.$refs.nameInput.focus();
			}, 500);
		},
		confirm(mode) {
			this.deleteMode = mode;
		},
		async del() {
			await this.listDelete(entityName, this.category, "category/del");
			this.$emit("done");
		},
		async done() {
			this.validated = true;
			if (!this.valid) return;

			const category = { ...this.category };
			await (this.isnew
				? this.listAdd(entityName, category, "category/add")
				: this.listUpdate(entityName, category, "category/update"));
			this.$emit("done");
		},
	},
	computed: {
		valid() {
			return this.category.name;
		},
		isnew() {
			return !this.category._id;
		},
	},
};
</script>

<style lang="scss" scoped>
.confirm {
	position: absolute;
	left: 0;
	right: 0;
}
</style>

import { getApps, initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";
import { sendEmailVerification } from "firebase/auth";
import {
	createUserWithEmailAndPassword,
	getAuth,
	GoogleAuthProvider,
	onAuthStateChanged,
	signInWithEmailAndPassword,
	signInWithPopup,
} from "firebase/auth";

import router from "@/router";
import store from "@/store";
import http from "@/api/http";

import moment from "moment";

import {
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_PASSWORD,
	CREATE_USER_WITH_PASSWORD,
	SIGN_OUT,
	UPDATE_USER,
} from "./types";
import { GET_PROFILE } from "@/auth/client/store/types";

import { setToken, updateUserEmail } from "../../firebaseAuth";

const config = {
	projectId: process.env.VUE_APP_PROJECTID,
	apiKey: process.env.VUE_APP_API_KEY,
	authDomain: process.env.VUE_APP_AUTH_DOMAIN,
	databaseURL: process.env.VUE_APP_DATABASE_URL,
	storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
	measurementId: process.env.VUE_APP_MEASUREMENT_ID,
	appId: process.env.VUE_APP_APP_ID,
};

if (!getApps().length) {
	const app = initializeApp(config);
	initializeAnalytics(app);
}

const SIGNED_OUT = "SIGNED_OUT";
const SET_USER = "SET_USER";
const AUTHENTICATING = "AUTHENTICATING";

const state = {
	currentUser: {
		displayName: null,
		photoURL: null,
		email: null,
		uid: "",
	},
	isAuthenticating: true,
};

const getters = {
	user: (state) => state.currentUser,

	me: (state) => state.currentUser,
	isAuthenticating: (state) => state.isAuthenticating,
	isAuthenticated: (state) =>
		!state.isAuthenticating &&
		state.currentUser &&
		!!state.currentUser.uid &&
		!!getAuth().currentUser,
	displayName: (state) => state.currentUser && state.currentUser.displayName,
	photoURL: (state) => state.currentUser && state.currentUser.photoURL,
	missingUserInfo: (state) =>
		!state.currentUser.email ||
		!state.currentUser.displayName ||
		!state.currentUser.phoneNumber,
};

const actions = {
	[SIGNIN_WITH_PASSWORD]: ({ commit }, authData) => {
		const email = authData.email;
		const password = authData.password;
		return signInWithEmailAndPassword(getAuth(), email, password);
	},
	[CREATE_USER_WITH_PASSWORD]: ({ commit }, authData) => {
		const email = authData.email;
		const password = authData.password;
		return createUserWithEmailAndPassword(getAuth(), email, password);
	},
	[SIGNIN_WITH_GOOGLE]: ({ commit }) => {
		const provider = new GoogleAuthProvider();
		return signInWithPopup(getAuth(), provider);
	},
	[SIGN_OUT]: async ({ commit }) => {
		await getAuth().signOut();
		commit(SIGNED_OUT);
		commit("RESET");
	},
	[UPDATE_USER]: async ({ state, commit, dispatch }, data) => {
		commit("SAVING", true);
		if (data.oldEmail != data.email) {
			await updateUserEmail(data.email);
			delete data.oldEmail;
		}

		return http
			.post("/user/update", data)
			.then(async (resp) => {
				await setToken(true);
				commit(SET_USER, resp.data);

				store.dispatch(GET_PROFILE);
				return resp.data;
			})
			.catch((e) => {
				throw e.response;
			})
			.finally(() => {
				commit("SAVING", false);
			});
	},
};

const mutations = {
	[SET_USER]: (state, user) => {
		if (!user) {
			return;
		}
		const {
			displayName,
			photoURL,
			email,
			uid,
			phoneNumber,
			emailVerified,
		} = user;
		state.currentUser = {
			displayName,
			photoURL,
			email,
			uid,
			phoneNumber,
			emailVerified,
		};
	},

	[SIGNED_OUT]: (state) => {
		state.currentUser = {
			displayName: null,
			photoURL: null,
			email: null,
			uid: "",
			emailVerified: "",
		};
		localStorage.removeItem("currentcid");
		router.replace("/login");
	},
	[AUTHENTICATING]: (state, value) => {
		state.isAuthenticating = value;
	},
};

export const isAppInitialized = new Promise((resolve) => {
	onAuthStateChanged(getAuth(), async (user) => {
		if (user) {
			console.log("User logged in ");
			console.log("user.emailVerified ", user.emailVerified);
			if (!user.emailVerified) {
				const sentEmailVerified = "sent_verified_" + user.email;
				const day = moment().format("YYYYMMDDHH");
				//send email once a day
				const dayLocalStorage = localStorage.getItem(sentEmailVerified);
				if (!dayLocalStorage || dayLocalStorage != day) {
					sendEmailVerification(user)
						.then(() => {
							localStorage.setItem(sentEmailVerified, day);
							console.log("Email Vericication Sent :");
						})
						.catch((err) => {
							console.log("err :", err);
						});
				}
			}
			store.commit(SET_USER, user);

			await setToken(false).then((o) => {
				store.dispatch(GET_PROFILE);
				store.commit(AUTHENTICATING, false);
				onSuccess();
			});
		} else {
			store.commit(AUTHENTICATING, false);
		}
		resolve(true);
	});
});

function onSuccess() {
	// setTimeout(() => {
	let callback = router ? router.currentRoute.query.callback : "";
	console.log("callback", callback);
	if (callback == "/settings/useraccess/0") callback = "/settings";
	router.push(callback).catch((err) => {});
	// }, 100);
}

let errTime;
// Add a response interceptor
http.interceptors.response.use(
	(response) => response,
	(error) => {
		const status = error.response ? error.response.status : null;
		if (status === 401) {
			// if last error happened less than half an hour ago
			if (errTime) {
				let time = moment
					.duration(moment(new Date()).diff(errTime))
					.asMinutes();
				if (time < 30) return router.replace("/login");
			}
			return setToken()
				.then(() => {
					// console.log("re authenticate", error.config.url);
					errTime = moment(new Date());
					return http.request(error.config.url);
				})
				.catch((e) => {
					console.log("e :", e);
					router.replace("/login");
				});
		}
		return Promise.reject(error);
	}
);

export default {
	getters,
	state,
	actions,
	mutations,
};

<template>
	<div>
		<search-input
			placeholder="Search item, contact, project, reference or notes"
			@change="change"
			v-model="options.keyword"
			class="mb-4"
		/>
		<filter-project v-model="options.projectId" @change="change" />
		<filter-contact
			v-model="options.contactId"
			@change="change"
			placeholder="Filter Contact"
			v-if="!this.isUserCustomer"
		/>
		<filter-item v-model="options.itemId" @change="change" v-if="hasItem" />
		<filter-account
			v-model="options.accountId"
			@change="change"
			v-if="hasAccount && !isUserCustomer"
		/>
		<DateRange v-model="options.dates" @change="dateChange" />
		<div class="text-center mt-3" v-if="hasPaid">
			<toggle-buttons
				:items="paidTypes"
				@select="change"
				v-model="options.paid"
			/>
		</div>
	</div>
</template>
<script>
import FilterProject from "@/views/project/components/FilterProject.vue";
import FilterContact from "@/views/contact/components/FilterContact.vue";
import FilterItem from "@/views/itemmaster/components/FilterItem.vue";
import FilterAccount from "@/views/account/components/FilterAccount.vue";
import { cleanNulls } from "@/helpers/map";
import DateRange from "./DateRange";
import accessMixin from "@/mixins/accessMixin";
import { mapGetters } from "vuex";

export default {
	name: "reportFilters",
	props: {
		placeholder: String,
		hasItem: Boolean,
		hasAccount: Boolean,
		hasPaid: Boolean,
	},
	mixins: [accessMixin],
	components: {
		FilterProject,
		FilterContact,
		FilterItem,
		FilterAccount,
		DateRange,
	},
	data() {
		return {
			paidTypes: [
				{ text: "All", code: "" },
				{ text: "Paid", code: "f" },
				{ text: "Partially paid", code: "p" },
				{ text: "Unpaid", code: "u" },
			],
			options: {
				keyword: "",
				projectId: "",
				contactId: "",
				itemId: "",
				accountId: "",
				dates: {
					start: null,
					end: null,
					code: "",
				},
				paid: "",
			},
		};
	},
	mounted() {
		this.loadFromStore();
		this.change();
	},
	methods: {
		dateChange(dates) {
			this.options.dates = dates;
			this.change();
		},
		change() {
			let params = { ...this.options };
			params.start = this.options.dates ? this.options.dates.start : null;
			params.end = this.options.dates ? this.options.dates.end : null;
			params.dates = "";
			cleanNulls(params);
			this.commitSelected();
			this.$emit("change", params);
		},
		loadFromStore() {
			for (const key in this.options) {
				this.options[key] = this.selectedByKey(key);
			}
		},
		commitSelected() {
			for (const key in this.options) {
				this.$store.commit("SET_SELECTED", {
					key,
					value: this.options[key],
				});
			}
		},
	},

	computed: {
		...mapGetters(["selectedByKey"]),
	},
};
</script>
